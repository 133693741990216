<template>
  <div>
    <div class="row">
      <div class="col-xxl-12">
        <ListTable />
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import ListTable from '../components/ListTable.vue';

export default {
  name: 'List',
  components: {
    ListTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'News Category', route: 'news_category'}]);
  },
  methods: {},
};
</script>

<style></style>
